export const PAGES = {
  INDEX: "/",
  NOT_FOUND: "/404",
  LEGAL: {
    TERMS: "/terms",
    PRIVACY: "/privacy",
  },
  AUTH: {
    SIGN_IN: "/auth/signin",
    SIGN_OUT: "/auth/signout",
    SIGN_UP: "/auth/signup",
    FORGOT_PASSWORD: "/auth/forgot-password",
    RESET_PASSWORD: "/auth/reset-password",
  },
  SETTINGS: "/settings",
  ORGANIZATIONS: "/organizations",
  APP: {
    INDEX: (organizationSlug: string) => `/app/${organizationSlug}`,
    BRAND_SETTINGS: (organizationSlug: string, brandName: string) =>
      `/app/${organizationSlug}/${brandName}/settings`,
    DEFAULT_TARGET: (organizationSlug: string, brandName: string) =>
      `/app/${organizationSlug}/${brandName}/insights/summary`,
    INSIGHTS: {
      SUMMARY: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/insights/summary`,
      ROI: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/insights/roi`,
      HISTORICAL_SALES: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/insights/historical-sales`,
      STORE_INSIGHTS: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/insights/store-insights`,
      STORE_INSIGHTS_PLAYGROUND: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/insights/store-insights-playground`,
      DEMOGRAPHICS: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/insights/demographics`,
    },
    PROMOTIONS: {
      INDEX: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/promotions`,
      PROMOTION: (organizationSlug: string, brandName: string, promotionId: string) =>
        `/app/${organizationSlug}/${brandName}/promotions/${promotionId}`,
    },
    DEMOS: {
      INDEX: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/demos`,
    },
    DISTRIBUTORS: {
      INVENTORY_FORECAST: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/distributors/inventory-forecast`,
    },
    STORES: {
      INDEX: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/stores`,
      STORE: {
        INDEX: (organizationSlug: string, brandName: string, storeId: string) =>
          `/app/${organizationSlug}/${brandName}/stores/${storeId}`,
      },
    },
    GOD: {
      INDEX: `/app/god`,
    },
  },
  API: {
    GMAIL: {
      LOGIN_CALLBACK: "/api/gmail/login-callback",
    },
    OUTLOOK: {
      LOGIN_CALLBACK: "/api/outlook/login-callback",
    },
  },
} as const;
