import { defaultShouldDehydrateQuery, QueryClient } from "@tanstack/react-query";

// https://trpc.io/docs/client/react/server-components
export function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchInterval: false,
        refetchOnWindowFocus: false,
      },
      dehydrate: {
        shouldDehydrateQuery: (query) =>
          defaultShouldDehydrateQuery(query) || query.state.status === "pending",
      },
      hydrate: {},
    },
  });
}
