"use client";

import { makeQueryClient } from "@/trpc/query-client";
import { trpc } from "@/utils/trpc-app";
import { QueryClientProvider } from "@tanstack/react-query";
import { getFetch, httpBatchLink } from "@trpc/react-query";
import { useState } from "react";

const queryClient = makeQueryClient();

export default function TrpcProvider({ children }: { children: React.ReactNode }) {
  // NOTE: Your production URL environment variable may be different
  const url =
    process.env.NEXT_PUBLIC_URL && !process.env.NEXT_PUBLIC_URL.includes("localhost")
      ? `${process.env.NEXT_PUBLIC_URL}/api/trpc/`
      : "http://localhost:1999/api/trpc/";

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url,
          fetch: async (input, init?) => {
            const fetch = getFetch();
            return fetch(input, {
              ...init,
              credentials: "include",
            });
          },
        }),
      ],
    }),
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
