import(/* webpackMode: "eager", webpackExports: ["Root","displayName","List","Item","Trigger","Content","Link","Viewport","Indicator"] */ "/app/node_modules/@radix-ui/react-navigation-menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\",\"latin-ext\"],\"weight\":[\"400\",\"500\",\"700\"],\"variable\":\"--font-main\"}],\"variableName\":\"nunito\"}");
;
import(/* webpackMode: "eager" */ "/app/public/treater.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AppBaseLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Navigation/ActiveSessionLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Providers/trpc.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
