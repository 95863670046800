"use client";

import { SessionProvider } from "next-auth/react";
import PostHogProvider from "./Providers/PostHogProvider";
import Footer from "./Navigation/Footer";
import ToasterProvider from "./Providers/ToasterProvider";
import CookieConsent from "./CookieConsent/CookieConsent";
import FullstoryProvider from "./Providers/FullstoryProvider";

export default function AppBaseLayout({ children }: { children: React.ReactNode }) {
  return (
    <SessionProvider>
      <FullstoryProvider />
      <PostHogProvider>
        <div className="flex grow">{children}</div>
        <Footer />
        <ToasterProvider />
        <CookieConsent />
      </PostHogProvider>
    </SessionProvider>
  );
}
