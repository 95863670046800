"use client";

import { PAGES } from "@/utils/pages";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuItem,
} from "../ui/dropdown-menu";
import { PersonIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import React from "react";

export default function ActiveSessionLinks() {
  return (
    <div className="flex flex-row space-x-4">
      <ProfileLinks />
    </div>
  );
}

function ProfileLinks() {
  const links = [
    { title: "Sign out", url: PAGES.AUTH.SIGN_OUT },
    { title: "Settings", url: PAGES.SETTINGS },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <PersonIcon className="h-6 w-6" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {links.map(({ title, url }, i) => (
          <React.Fragment key={i}>
            {i > 0 && <DropdownMenuSeparator />}
            <DropdownMenuItem>
              <Link className="cursor-pointer hover:text-blue-link whitespace-nowrap" href={url}>
                {title}
              </Link>
            </DropdownMenuItem>
          </React.Fragment>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
